export const Color = {
  brand: {
    // ⚠️ no more then four
    100: "#620101",
    200: "#c30202",
    300: "#ffe7a9",
    400: "#ffe194",
    500: "#CC9300",
  },
  "red": {
    "50": "#FFE6E6",
    "100": "#FEB8B8",
    "200": "#FE8B8B",
    "300": "#FD5D5D",
    "400": "#FD3030",
    "500": "#FC0303",
    "600": "#CA0202",
    "700": "#970202",
    "800": "#650101",
    "900": "#320101"
  },
  yellow: {
    50: "#FFF8E5",
    100: "#FFEBB8",
    200: "#FFDE8A",
    300: "#FFD15C",
    400: "#FFC42E",
    500: "#FFB800",
    600: "#CC9300",
    700: "#996E00",
    800: "#664900",
    900: "#332500",
  },

  gray: {
    50: "#F4F3F0",
    100: "#E1DFD5",
    200: "#CECABB",
    300: "#BBB5A0",
    400: "#A8A085",
    500: "#958B6A",
    600: "#776F55",
    700: "#595440",
    800: "#3B382B",
    900: "#1E1C15",
  },
}
